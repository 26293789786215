import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LiveTv } from "@mui/icons-material";
import { artistKey, API_BASE, currentUser } from "@/App";

export default function LiveBanner() {
  const [status, setStatus] = useState("stopped");
  const [contentKey, setContentKey] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const fetchStatus = async () => {
    try {
      const endpoint = `${API_BASE}/check_broadcast_channel`;
      const response = await axios.get(endpoint, {
        params: { artist_key: artistKey },
      });
      setStatus(response.data.status);
      setContentKey(response.data.key);
    } catch (error) {
      console.error("Failed to fetch broadcast status:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
    const intervalId = setInterval(fetchStatus, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const isBroadcaster = currentUser.key === artistKey;
    const shouldShow =
      (isBroadcaster && ["waiting", "live"].includes(status)) ||
      (!isBroadcaster && status === "live");

    setShow(shouldShow);
  }, [status]);

  const handleClick = () => {
    // if artist need the URI as well
    // fetch from /broadcast uri?

    navigate(`/live?key=${contentKey}`);
  };

  if (!show) return null;

  return (
    <AppBar position="sticky" elevation={10} sx={{ zIndex: 60 }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#9A5AEF",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Montserrat Alternates', sans-serif",
            fontWeight: 600,
            fontSize: "1.4rem",
            mb: 0,
          }}
        >
          {artistKey} live
        </Typography>

        <Button
          color="secondary"
          size="large"
          onClick={handleClick}
          startIcon={<LiveTv sx={{ mb: 0.5 }} />}
          sx={{
            pr: 0,
            borderRadius: 20,
            borderWidth: 2,
            borderColor: "rgba(4, 255, 255, 1) !important",
          }}
        >
          {currentUser.key === artistKey ? "Resume" : "Join"}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
