import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Container,
  TextField,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowUpward, Groups } from "@mui/icons-material";

import { useSearchParams } from "react-router-dom";

import CaptionContainer from "@/fragments/CaptionContainer";
import CommentCard from "@/components/CommentCard";

import Broadcaster from "./Broadcaster";
import Viewer from "./Viewer";

import { API_BASE, artistKey, currentUser } from "@/App";

export default function LiveContainer() {
  const [searchParams] = useSearchParams();
  const contentKey = searchParams.get("key");

  const [content, setContent] = useState(null);
  const [status, setStatus] = useState(null);
  const [viewerCount, setViewerCount] = useState(0);
  const [comments, setComments] = useState([]);

  const fetchBroadcast = async () => {
    // console.log("Fetching content...");
    try {
      const response = await axios.get(API_BASE + "/broadcast", {
        params: {
          key: contentKey,
          user_key: currentUser.key,
        },
      });
      // console.log("Broadcast data:", response.data);
      setContent(response.data.content);
      setViewerCount(response.data.viewer_count - 1);
      setStatus(response.data.status);

      if (response.data.status === "stopped") {
        alert("Broadcast has ended.");
        window.location.href = "/";
      }

      const comments = response.data.comments;
      const sortedComments = comments.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });
      setComments(sortedComments);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  useEffect(() => {
    fetchBroadcast();
  }, [contentKey]);

  // re render ever 5 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      fetchBroadcast();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      {/* if its an artist, show viewer count */}
      {currentUser.key === artistKey ? (
        <>
          <Broadcaster
            status={status}
            viewerCount={viewerCount}
            fetchBroadcast={fetchBroadcast}
          />
        </>
      ) : (
        <Viewer content={content} status={status} />
      )}

      <CommentsContainer
        contentKey={contentKey}
        comments={comments}
        content={content}
        fetchBroadcast={fetchBroadcast}
      />
    </Box>
  );
}

function CommentsContainer({ contentKey, comments, content, fetchBroadcast }) {
  // console.log("Comments:", comments);

  return (
    <Box sx={{ p: 2 }}>
      {comments?.map((event, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <CommentCard
            event={event}
            fetchComments={fetchBroadcast}
            content={content}
          />
        </Box>
      ))}

      {content && (
        <CaptionContainer content={content} helperText="Sent as SMS" />
      )}

      <NewCommentContainer
        contentKey={contentKey}
        fetchBroadcast={fetchBroadcast}
      />
    </Box>
  );
}

function NewCommentContainer({ contentKey, fetchBroadcast }) {
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setSubmitting(true);
    const data = {
      content_key: contentKey,
      user_key: currentUser.key,
      type: "comment",
      data: {
        text: comment,
      },
    };
    try {
      const endpoint = API_BASE + "/event";
      const response = await axios.post(endpoint, data);
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setSubmitting(false);
      setComment("");
      fetchBroadcast();
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#121212",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            py: 4,
          }}
        >
          <Avatar
            src={currentUser.meta.profile}
            alt={currentUser.username}
            sx={{ width: 34, height: 34, mt: 0.5 }}
          />
          <TextField
            fullWidth
            multiline
            autoComplete="off"
            maxRows={4}
            size="small"
            placeholder="Join the conversation..."
            variant="outlined"
            value={comment}
            sx={{ mx: 1 }}
            disabled={submitting}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box>
            <IconButton
              size="small"
              style={{ background: "#9A5AEF" }}
              onClick={handleSubmit}
              disabled={submitting || comment.length === 0}
              sx={{ mt: 0.5 }}
            >
              <ArrowUpward />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
