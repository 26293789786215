import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  CloseFullscreen,
  Groups,
  LiveTv,
  OpenInFull,
  ReplayOutlined,
  StopCircle,
} from "@mui/icons-material";

import { API_BASE, artistKey, currentUser } from "@/App";
import CopyLink from "@/components/CopyLink";
import { getWsBase } from "@/utils";
import NewNotificationContainer from "@/components/NewNotificationContainer";

const WS_BASE = getWsBase();

export default function Broadcaster({ fetchBroadcast, status, viewerCount }) {
  const [searchParams] = useSearchParams();
  const contentKey = searchParams.get("key");
  const uri = searchParams.get("uri");

  // ui helpers
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [compact, setCompact] = useState(false);
  const [paused, setPaused] = useState(false);

  // first create and start the channel

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);

  // now open the socket connection and start streaming
  useEffect(() => {
    if (!uri) return;

    const socket = new WebSocket(`${WS_BASE}?uri=${encodeURIComponent(uri)}`);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log("WebSocket connected");
      setTimeout(() => startStreaming(), 500);
    };

    socket.onclose = () => {
      console.log("WebSocket disconnected");
      // stopVideoStream(); // what do I gain from this?
      setPaused(true);
      setError("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setError("WebSocket connection failed");
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    };
  }, [uri]);

  const startStreaming = useCallback(async () => {
    console.log("Starting stream...");
    try {
      if (typeof MediaRecorder === "undefined" || !navigator.mediaDevices) {
        throw new Error(
          "MediaRecorder API or media devices are not supported in this browser."
        );
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 480, max: 480 },
          height: { ideal: 480, max: 480 },
          frameRate: { ideal: 30, max: 30 },
          facingMode: "user",
        },
        audio: true,
      });

      videoRef.current.srcObject = stream;

      const mimeTypes = [
        'video/mp4;codecs="avc1.42E01E, mp4a.40.2"',
        "video/webm;codecs=vp8,opus",
        "video/webm;codecs=vp8",
        "video/webm",
      ];

      const mimeType = mimeTypes.find((type) =>
        MediaRecorder.isTypeSupported(type)
      );

      if (!mimeType) {
        throw new Error("No suitable MIME type found for MediaRecorder.");
      }

      console.log("Using MIME type:", mimeType);

      const videoBitrate = 1000 * 1000;
      const audioBitrate = 128 * 1000;

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        videoBitsPerSecond: videoBitrate,
        audioBitsPerSecond: audioBitrate,
      });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && socketRef.current) {
          try {
            if (socketRef.current.readyState === WebSocket.OPEN) {
              socketRef.current.send(event.data);
            } else {
              console.warn("WebSocket is closed, data not sent.");
            }
          } catch (err) {
            console.error("Error sending video-data:", err);
          }
        }
      };
      mediaRecorder.start(2000);
      mediaRecorderRef.current = mediaRecorder;
    } catch (err) {
      console.error("Error starting stream:", err);
      setError(err.message);
    }
  }, []);

  // then keep getting updated information every 5 seconds

  return (
    <Box>
      <PageAppBar
        loading={loading}
        mediaRecorderRef={mediaRecorderRef}
        videoRef={videoRef}
        socketRef={socketRef}
        contentKey={contentKey}
      />

      {/* End Stream Confirmation Dialog */}

      <Box sx={{ mt: 1, px: 2 }}>
        {/* Video Container */}
        <Box
          sx={{
            position: compact ? "fixed" : "relative",
            bottom: compact ? 110 : "auto",
            left: compact ? 10 : "auto",
            zIndex: compact ? 1000 : "auto",
            width: compact ? "33%" : "100%",
            height: compact ? "auto" : "100%",
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #343332",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "1 / 1",
          }}
        >
          {/* {paused && (
            <Typography variant="h6">
              Stream Paused
            </Typography>
          )} */}
          {/* {error && !loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() => window.location.reload()}
            >
              <ReplayOutlined sx={{ my: 2 }} size={30} />
              <Typography color="error">{error}</Typography>
            </Box>
          )} */}
          {/* {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress sx={{ my: 2 }} />
              <Typography variant="h6">Ending Live Stream...</Typography>
              <Typography color="darkgrey" sx={{ my: 1 }}>
                This can take several moments
              </Typography>
            </Box>
          )} */}

          {true && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: "scaleX(-1)",
                }}
              />
              <IconButton
                onClick={() => setCompact((prev) => !prev)}
                size="small"
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
              >
                {compact ? (
                  <OpenInFull sx={{ color: "white", fontSize: 14 }} />
                ) : (
                  <CloseFullscreen sx={{ color: "white", fontSize: 20 }} />
                )}
              </IconButton>
              <Chip
                label={status}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            my: 2,
            justifyContent: "space-between",
          }}
        >
          <CopyLink artist={currentUser} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Groups sx={{ mx: 1 }} color="primary" />
            <Typography variant="body1" color="textSecondary" sx={{ mx: 1 }}>
              {viewerCount || 0}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* {console.log("contentKey", contentKey)} */}
      <NewNotificationContainer
        contentKey={contentKey}
        fetchBroadcast={fetchBroadcast}
      />
    </Box>
  );
}

function PageAppBar({ mediaRecorderRef, videoRef, socketRef, contentKey }) {
  const [endStreamDialogOpen, setEndStreamDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const stopVideoStream = useCallback(() => {
    // uh is this duplicative?

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }

    if (videoRef.current?.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  }, []);

  const handleEndStreamConfirm = async () => {
    try {
      stopVideoStream();
      setLoading(true);
      const endpoint = `${API_BASE}/end_broadcast`;
      await axios.post(endpoint, { key: contentKey });
      // wait three seconds then navgate to home
      // window.location.href = "/";

      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } catch (error) {
      console.error("Error ending stream:", error);
      setError("Failed to end stream");
      setLoading(false);
    }
    // } finally {
    //   setEndStreamDialogOpen(false);
    // }
  };

  return (
    <>
      <Dialog
        open={endStreamDialogOpen}
        // onClose={() => setEndStreamDialogOpen(false)}
        aria-labelledby="end-stream-dialog-title"
        aria-describedby="end-stream-dialog-description"
      >
        <DialogTitle id="end-stream-dialog-title">End Live Stream</DialogTitle>
        <DialogContent>
          <DialogContentText id="end-stream-dialog-description">
            Are you sure you want to end this live stream?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Button
            onClick={() => setEndStreamDialogOpen(false)}
            color="info"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEndStreamConfirm}
            color="error"
            variant="outlined"
            sx={{ borderRadius: "30px" }}
            size="small"
            autoFocus
            endIcon={loading ? <CircularProgress size={16} /> : <StopCircle />}
            disabled={loading}
          >
            End
          </Button>
        </DialogActions>
      </Dialog>

      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            className="artist-logo"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LiveTv color="secondary" />

            <Typography
              style={{
                fontFamily: "'Montserrat Alternates', sans-serif",
                fontWeight: 600,
                fontSize: "1.4rem",
                marginBottom: "0px",
                marginLeft: "12px",
              }}
            >
              {artistKey} live
            </Typography>
          </Box>

          <Button
            variant="outlined"
            size="small"
            onClick={() => setEndStreamDialogOpen(true)}
            color="error"
            endIcon={<StopCircle />}
            sx={{
              borderRadius: "30px",
              mt: 0.5,
            }}
          >
            End
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
