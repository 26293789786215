import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  CircularProgress,
  Alert,
  Chip,
  CardMedia,
} from "@mui/material";
import { Cancel, LiveTv } from "@mui/icons-material";
import Hls from "hls.js";
import { artistKey } from "@/App";
import { getUIStage } from "@/utils";

const LIVESTREAM_BASE = `https://storage.googleapis.com/nb-live-${getUIStage()}`;

export default function Viewer({ content, status }) {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadHlsStream = useCallback(() => {
    if (!content?.key) return;

    const hlsUrl = `${LIVESTREAM_BASE}/${content.key}/manifest.m3u8`;
    console.log("Loading HLS stream:", hlsUrl);
    if (!videoRef.current) return;

    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    setIsLoading(true);
    setError(null);

    if (!Hls.isSupported()) {
      setError("Your browser does not support HLS playback.");
      setIsLoading(false);
      return;
    }

    try {
      const hls = new Hls({
        liveDurationInfinity: true,
        backBufferLength: 30,
      });

      hls.loadSource(hlsUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (videoRef.current) {
          videoRef.current.duration = Infinity;
          videoRef.current.play().catch((error) => {
            console.error("Playback error:", error);
            setError("Failed to start playback. Please try again.");
          });
        }
        setIsLoading(false);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS error:", data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              setError("Network error. Retrying...");
              setTimeout(() => loadHlsStream(), 5000);
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              setError("Media error. Attempting to recover...");
              hls.recoverMediaError();
              break;
            default:
              setError("Fatal streaming error. Please try again later.");
              hls.destroy();
          }
        }
      });

      hlsRef.current = hls;
    } catch (err) {
      console.error("Stream initialization error:", err);
      setError("Failed to initialize stream. Please try again.");
      setIsLoading(false);
    }
  }, [content?.key]);

  useEffect(() => {
    loadHlsStream();

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [loadHlsStream]);

  return (
    <>
      <PageAppBar status={status} />
      <Box sx={{ mt: 1, px: 2, mb: 2 }}>
        <Box
          sx={{
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #343332",
            aspectRatio: "1 / 1",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* {console.log(content.owner.meta.profile)} */}
          {content && status !== "live" ? (
            <>
              <CardMedia
                image={content.owner.meta.profile}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0.3,
                }}
              />
            </>
          ) : (
            <video
              ref={videoRef}
              controls
              playsInline
              autoPlay
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
          <Chip
            label={status}
            sx={{
              position: "absolute",
              bottom: 10,
              right: 10,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </>
  );
}

function PageAppBar({ status }) {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        background: "rgba(19,19,19,.9)",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          className="artist-logo"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {status === "live" ? (
            <LiveTv color="secondary" />
          ) : (
            <CircularProgress color="secondary" size={20} />
          )}
          <Typography
            sx={{
              fontFamily: "'Montserrat Alternates', sans-serif",
              fontWeight: 600,
              fontSize: "1.3rem",
              mb: 0,
              ml: 1.5,
            }}
          >
            {artistKey} live
          </Typography>
        </Box>
        <IconButton onClick={() => navigate(-1)} aria-label="close stream">
          <Cancel color="info" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
