import axios from "axios";
import { useState } from "react";

import { ArrowUpward, Cancel, Close, Send } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  TextField,
  SwipeableDrawer,
  Button,
} from "@mui/material";

import { API_BASE, currentUser } from "@/App";

export default function NewNotificationContainer({
  contentKey,
  fetchBroadcast,
}) {
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [open, setOpen] = useState(true);

  const [subscriberCount, setSubscriberCount] = useState(0);

  // console.log("NewCommentContainer", contentKey);
  // get the count of

  // make sure this is a secure route
  const handleSubmit = async (e) => {
    console.log(contentKey);
    setSubmitting(true);
    const data = {
      content_key: contentKey,
      user_key: currentUser.key,
      meta: {
        text: comment,
      },
    };
    try {
      const endpoint = API_BASE + "/content/notification";
      const response = await axios.post(endpoint, data);
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setSubmitting(false);
      setComment("");
      fetchBroadcast();
      setOpen(false);
    }
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(!open)}
      PaperProps={{ style: { borderRadius: "20px 20px 0 0" } }}
    >
      <Container maxWidth="sm">
        <Button
          onClick={() => setOpen(!open)}
          variant="text"
          color="info"
          sx={{ mt: 2 }}
          // startIcon={<Close />}
        >
          Skip
        </Button>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            py: 4,
          }}
        >
          <Avatar
            src={currentUser.meta.profile}
            alt={currentUser.username}
            sx={{ width: 34, height: 34, mt: 0.5 }}
          />
          <TextField
            fullWidth
            multiline
            autoComplete="off"
            maxRows={4}
            size="small"
            placeholder="Send a message to your fans and invite to live stream..."
            helperText="sent as SMS to your subscribers"
            variant="outlined"
            value={comment}
            sx={{ mx: 1 }}
            disabled={submitting}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box>
            <IconButton
              size="small"
              style={{ background: "#9A5AEF" }}
              onClick={handleSubmit}
              disabled={submitting || comment.length === 0}
              sx={{ mt: 0.5 }}
            >
              <Send />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </SwipeableDrawer>
  );
}
