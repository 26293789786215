import React from "react";
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Typography,
} from "@mui/material";
import { Add, LiveTv } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE, currentUser } from "../App";

export default function FloatingButtons() {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);

  const handleGoLive = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/go_live";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
    });
    navigate(`/live?key=${response.data.key}&uri=${response.data.uri}`);
    setLoading(false);
  };

  if (currentUser.role !== "artist" || window.location.pathname === "/post") {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 120,
        right: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 4,
        zIndex: 500,
        filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          Post
        </Typography>
        <Fab
          color="primary"
          aria-label="add post"
          onClick={() => navigate("/post")}
        >
          <Add />
        </Fab>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          Go Live
        </Typography>
        <Fab color="secondary" onClick={handleGoLive}>
          {/* <LiveTv /> */}
          {loading ? <CircularProgress size={24} /> : <LiveTv />}
        </Fab>
      </Box>
    </Box>
  );
}
