import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";

import { MusicContext } from "../../contexts/MusicContext";
import { getContentArtwork } from "../../utils";
import zIndex from "@mui/material/styles/zIndex";

export default function CompactPlayer({ compact, setCompact }) {
  const {
    currentSong,
    handleClearSong,
    isPlaying,
    handlePause,
    handlePlay,
    duration,
    currentTime,
    handleTimeSliderChange,
  } = useContext(MusicContext);

  const filteredTags = currentSong.meta.tags
    ? currentSong.meta.tags.filter((tag) => tag.trim() !== "")
    : [];

  return (
    <Box
      id="compact-player-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "#383838",
        borderRadius: "10px",
        position: "fixed",
        bottom: "82px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        zIndex: 1000,
        // transformX: "50%",
      }}
      sx={{ p: 1 }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          id="song-info"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setCompact(!compact)}
        >
          <CardMedia
            component="img"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "5px",
            }}
            sx={{ mr: 1 }}
            src={getContentArtwork(currentSong)}
          />
          <Box
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "220px",
            }}
          >
            <Typography className="marquee">
              {currentSong.meta["title"]}
            </Typography>
            <Typography sx={{ color: "darkgray", fontSize: "0.8em" }}>
              {currentSong.owner.name}
              {filteredTags.length > 0 && `, ${filteredTags.join(", ")}`}
            </Typography>
          </Box>
        </Box>
        <Box id="player-controls" style={{ flexShrink: 0 }}>
          <IconButton onClick={handleClearSong} style={{ marginRight: "10px" }}>
            <CloseIcon style={{ fontSize: "25px" }} color="info" />
          </IconButton>

          {isPlaying ? (
            <IconButton onClick={handlePause}>
              <PauseIcon style={{ fontSize: "30px" }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => handlePlay(currentSong)}>
              <PlayArrowIcon style={{ fontSize: "30px" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Slider
        value={currentTime}
        min={0}
        max={duration}
        size="small"
        color="secondary"
        onChange={handleTimeSliderChange}
        sx={{
          "& .MuiSlider-thumb": {
            display: "none",
          },
          position: "absolute",
          left: 10,
          bottom: 0,
          width: "calc(100% - 20px)",
        }}
        style={{
          margin: 0,
          padding: 0,
        }}
      />
    </Box>
  );
}
