import React from "react";

import { Avatar, Box, TextField } from "@mui/material";

import { currentUser } from "../../App";

export default function ContentText({ text, setText, placeholder }) {
  return (
    <Box style={{ display: "flex", alignItems: "flex-start" }}>
      <Avatar
        src={currentUser.meta.profile}
        sx={{ width: 48, height: 48, mr: 1 }}
        style={{
          border: 1,
          borderColor: "#04FFFF",
          borderStyle: "solid",
          marginTop: "4px",
        }}
      />
      <TextField
        fullWidth
        multiline
        // size="small"
        sx={{ mt: 0 }}
        autoComplete="off"
        placeholder={placeholder || "Include a message..."}
        helperText="This will be sent as SMS to your subscribers."
        value={text}
        onChange={(e) => setText(e.target.value)}
        margin="normal"
        InputProps={{
          style: {
            borderRadius: "30px",
          },
        }}
      />
    </Box>
  );
}
